.all_content{
    position: relative;
    display: flex;
    height: 100%;
    min-height: 80vh;
    width: 100%;
}
.content_main{  
    background-color: #fff;
    width: 100%;
}
.page_titile{
    display: flex;
    flex-direction: column;
    font-weight: 700;
    text-align: center;
    margin: 30px 0;
    gap: 10px;
}
.page_titile h1{
    text-transform: uppercase;
}

.page_subtitle{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 30px;
}
.page_subtitle h2{
    text-align: center;
}
.files_title_text{
    font-weight: 700;
}
.page_files{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 30px;
    background-color: #dbd9d9;
    min-height: 50px;
    max-height: max-content;
    padding: 5px;
    border: solid #dbd9d9 1px;
}
.page_files a{
    color: #71b1d1;
}

.page_files:nth-child(even){
    background-color: #fff;
}
.about_wrapper{
    margin: 10px;
}
.btn_group{
    display: flex;
    flex-wrap: wrap;
    width: inherit;
    margin: 20px;
    justify-content: space-evenly;
    gap: 10px;
}
.btn_report{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    background-color: inherit;
    width: 250px;
    height: 40px;
    color: #71b1d1;
    border: solid #71b1d1 2px;
    border-radius: 3px;
}
.btn_report:hover{
    background-color: #89b9d1;
    color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}
.btn_report a{
    color: inherit;
}
.dropdown_pull{
    position: absolute;
    padding: 0;
    top: 38px;
    border: #71b1d1 solid 1px;
    width: inherit;
    display: flex;
    flex-direction: column;
    background-color: #71b1d1;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.btn_report:hover .dropdown_pull{
    cursor: pointer;
    border-top: none;
    opacity: 1;
    transition: opacity 0.1s linear;
    visibility: visible;
}
.dropdown_pull li{
    padding: 10px;
}
.dropdown_pull li:hover{
    background-color: #89b9d1;
}

@media(max-width: 750px){
    .content_main{
        padding-left: 50px;
    }
}
header{
    background-color: #e5f1fa;
}
.header_inner{
    position: relative;
    overflow: hidden;
    max-height: 250px;
}
.header_img img{
    width: 100%;
    height: auto;
}
